import { FC } from "react";
import { motion } from "framer-motion";

const placeholderImages = [
  "https://createsiteai.b-cdn.net/c/_/25bd6f3f-66a5-4ac2-8172-6785507e951b",
  "https://createsiteai.b-cdn.net/c/_/393c578b-84e1-40c2-85f5-add1499c7bc6",
  "https://createsiteai.b-cdn.net/c/_/2fe4577c-1840-4f60-8da0-8065b3922646",
  "https://createsiteai.b-cdn.net/c/_/2aa4bf8e-2ced-4ca1-b7d8-660d44914cb4",
  "https://createsiteai.b-cdn.net/c/_/030517cf-1698-4b23-b5e1-6c298646bbc9",
];

const NewsArticlesSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Everyone is Exploring GenAI Technology
        </motion.h2>
        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6 auto-rows-[200px]">
          {placeholderImages.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className={`relative rounded-2xl overflow-hidden ${
                index === 0 || index === 3 ? "row-span-2" : ""
              }`}
            >
              <img
                src={image}
                alt="Article placeholder"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <span className="text-white text-lg font-medium">
                  Article Coming Soon
                </span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsArticlesSlide;
