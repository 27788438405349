import { FC } from "react";
import Logo from "./components/Logo";
import BackgroundElements from "./components/BackgroundElements";
import ContentContainer from "./components/ContentContainer";
import Scene from "../KnowledgeGraph/Scene";
import Navigation from "../Navigation/Navigation";

const HeroSection: FC = () => {
  return (
    <section className="relative min-h-screen desktop:pb-32 laptop:pb-32 tablet:pb-24 mobile:pb-24 w-full bg-[#FAFAFA] overflow-hidden isolate">
      <div className="absolute inset-0 -z-10">
        <Scene />
      </div>
      <Logo />
      <Navigation />
      <BackgroundElements />
      <ContentContainer />
    </section>
  );
};

export default HeroSection;
