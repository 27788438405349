import { FC } from "react";
import { motion } from "framer-motion";
import { HiLightningBolt, HiScale, HiUserGroup } from "react-icons/hi";

const points = [
  {
    icon: HiLightningBolt,
    title: "Discovery Phase",
    content:
      "Every financial institution is exploring GenAI to improve research quality and analyst efficiency",
  },
  {
    icon: HiScale,
    title: "Build vs Buy",
    content:
      "Financial institutions weighing building internally vs. buying external GenAI tools",
  },
  {
    icon: HiUserGroup,
    title: "Behavioural Change",
    content:
      "Analysts are getting increasingly comfortable with adopting AI and new skills such as prompting",
  },
];

const LandscapeSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Current Landscape of GenAI in Finance
        </motion.h2>
        <div className="space-y-6">
          {points.map((point, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="flex items-start gap-6 bg-white p-8 rounded-2xl hover:shadow-lg transition-all duration-300 border border-gray-100"
            >
              <point.icon className="w-10 h-10 text-white flex-shrink-0 bg-black rounded-xl p-2" />
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 mb-3">
                  {point.title}
                </h3>
                <p className="text-gray-600 text-lg font-light leading-relaxed">
                  {point.content}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandscapeSlide;
