import { motion } from "framer-motion";
import { FC } from "react";

const CTAButtons: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6, delay: 0.3 }}
    className="flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col mobile:items-start gap-5"
  >
    <a
      href="https://form.typeform.com/to/uvfk1idp"
      target="_blank"
      rel="noopener noreferrer"
      className="group relative inline-block desktop:px-10 laptop:px-10 tablet:px-10 mobile:px-6 desktop:py-5 laptop:py-5 tablet:py-5 mobile:py-3 bg-black rounded-2xl overflow-hidden transition-all hover:shadow-lg hover:bg-gray-800 duration-300"
    >
      <span className="relative z-10 text-white font-medium tracking-wide">
        Get Started
      </span>
      <div className="absolute inset-0 bg-gray-800 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300" />
    </a>
    <a
      href="https://app.quantly-ai.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="group relative inline-block desktop:px-10 laptop:px-10 tablet:px-10 mobile:px-6 desktop:py-5 laptop:py-5 tablet:py-5 mobile:py-3 border border-black rounded-2xl overflow-hidden transition-all hover:shadow-lg hover:bg-black duration-300"
    >
      <span className="relative z-10 text-black font-medium tracking-wide group-hover:text-white transition-colors duration-300">
        Log In
      </span>
    </a>
  </motion.div>
);

export default CTAButtons;
