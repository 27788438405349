import { motion } from "framer-motion";
import { FC } from "react";
import { HiShieldCheck, HiChip, HiCloud, HiDatabase } from "react-icons/hi";

const features = [
  {
    icon: HiShieldCheck,
    title: "Enterprise-Grade Security",
    description:
      "The highest standards for data privacy and security throughout the whole process",
  },
  {
    icon: HiChip,
    title: "Cutting-edge AI",
    description: "Best-in-class proprietary AI tech applied to research",
  },
  {
    icon: HiCloud,
    title: "Custom End-to-End Deployment",
    description: "Flexible cloud, on-premise, or hybrid deployment options",
  },
  {
    icon: HiDatabase,
    title: "Single Source of Truth",
    description:
      "Unified platform combining internal and external data sources for research",
  },
];

const StatsPanel: FC = () => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.6, delay: 0.4 }}
    className="desktop:col-span-5 laptop:col-span-5 tablet:col-span-1 mobile:col-span-1 desktop:mt-0 laptop:mt-0 tablet:mt-8 mobile:mt-8"
  >
    <div className="space-y-4">
      {features.map((feature, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.5 + index * 0.1 }}
          className="bg-white rounded-2xl p-6 border border-gray-100 shadow-sm hover:shadow-lg transition-all duration-300 group"
        >
          <div className="flex items-start space-x-4">
            <div className="w-10 h-10 bg-black rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
              <feature.icon className="w-5 h-5 text-white" />
            </div>
            <div className="flex-1">
              <h3 className="text-gray-900 font-semibold mb-1 group-hover:translate-x-1 transition-transform duration-300">
                {feature.title}
              </h3>
              <p className="text-gray-500 text-sm font-light">
                {feature.description}
              </p>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  </motion.div>
);

export default StatsPanel;
