import { FC } from "react";
import FAQItem from "./components/FAQItem";

const faqs = [
  {
    category: "General",
    questions: [
      {
        question: "What is Quantly?",
        answer:
          "Quantly offers investment research infrastructure to allow financial institutions to leverage LLMs in their research processes without compromising on data privacy and security. Quantly is your in-house LLM research platform provider. We come in and deploy our research infrastructure to directly to you, offering as much assistance as needed.",
      },
      {
        question:
          "What types of financial institutions do you typically work with?",
        answer:
          "We work with investment banks, hedge funds, asset managers and private equity funds that have a research function, are looking into leveraging LLMs internally but are conscious of the security and data privacy implications of working with third parties.",
      },
      {
        question: "Can we trial Quantly's capabilities?",
        answer:
          "Yes. We have a sandbox trial environment of Quantly's technology, so that you can get an idea of Quantly's capabilities on your data.",
      },
    ],
  },
  {
    category: "Technology",
    questions: [
      {
        question:
          'What makes Quantly\'s financial LLM technology "best-in-class"?',
        answer:
          "Quantly's proprietary LLM technology achieves 94% accuracy on FinanceBench, the industry benchmark for financial Q&A performance. This is the highest score in the industry.",
      },
      {
        question: "Which LLMs do you use?",
        answer:
          "We use a combination of proprietary and open-source LLMs. Our technology stack is continuously evolving to incorporate the latest advancements in AI while maintaining security and reliability.",
      },
      {
        question: "How does Quantly ensure data privacy and security?",
        answer:
          "Quantly can be deployed entirely within your infrastructure. Your data never leaves your environment, and we adhere to the highest standards of data privacy and security protocols. We do not finetune LLMs on any of your data.",
      },
    ],
  },
  {
    category: "Integration",
    questions: [
      {
        question: "Can Quantly integrate with our existing document library?",
        answer:
          "Yes, Quantly is designed to integrate seamlessly with your research library and other internal data.",
      },
      {
        question: "Can we customize Quantly's solutions to our specific needs?",
        answer:
          "Absolutely. We work closely with clients to customize our solutions to their specific interface, workflows, and requirements. Our modular approach allows for extensive customization.",
      },
    ],
  },
  {
    category: "Data",
    questions: [
      {
        question:
          "What types of external data sources can Quantly integrate with?",
        answer:
          "Quantly can integrate with major financial data providers. We also support integration with alternative data sources based on client needs.",
      },
      {
        question: "What external data does Quantly support out of the box?",
        answer:
          "Quantly has fundamental company data including: company reports, earnings calls, presentations, press releases and consensus estimates on a global coverage of companies. Data is powered by S&P Capital IQ and Bloomberg.",
      },
    ],
  },
];

const FAQ: FC = () => {
  return (
    <section className="bg-white py-24">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600 text-lg">
            Find answers to common questions about Quantly
          </p>
        </div>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-x-12 gap-y-16">
          {faqs.map((category, index) => (
            <div key={index}>
              <h3 className="text-2xl font-semibold text-gray-900 mb-6 border-b border-gray-100 pb-4">
                {category.category}
              </h3>
              <div className="space-y-6">
                {category.questions.map((faq, faqIndex) => (
                  <FAQItem
                    key={faqIndex}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
