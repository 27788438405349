import React from "react";
import Hero from "../components/Hero";
import IntegrationProcess from "../components/IntegrationProcess/IntegrationProcess";
import AIPerformance from "../components/AIPerformance/AIPerformance";
import TechnologyPillars from "../components/TechnologyPillars/TechnologyPillars";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <AIPerformance />
      <TechnologyPillars /> <IntegrationProcess /> <FAQ />
      <Footer />
    </>
  );
};

export default Home;
