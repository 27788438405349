import { FC } from "react";
import { motion } from "framer-motion";

const IntroSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto text-center">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          src="https://createsiteai.b-cdn.net/c/_/4cc4f85d-86a6-46ed-b84c-c3111e974f89"
          alt="Quantly Logo"
          className="w-24 h-24 mx-auto mb-12"
        />
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-6xl font-bold text-gray-900 mb-6"
        >
          Welcome to Quantly
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="text-2xl text-gray-600"
        >
          Transform your investment research with enterprise-grade AI technology
        </motion.p>
      </div>
    </div>
  );
};

export default IntroSlide;
