import { Sphere } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";

interface NodeProps {
  position: [number, number, number];
  color?: string;
}

const Node = ({ position, color = "#ffffff" }: NodeProps) => {
  const [hovered, setHovered] = useState(false);
  const { camera } = useThree();
  const ref = useRef<THREE.Mesh>(null);

  useFrame((state) => {
    if (!ref.current) return;
    ref.current.position.y +=
      Math.sin(state.clock.elapsedTime + position[0]) * 0.001;
  });
  useFrame((state) => {
    if (!ref.current) return;

    if (hovered) {
      const time = state.clock.getElapsedTime();
      ref.current.position.x = position[0] + Math.sin(time * 2) * 0.1;
      ref.current.position.z = position[2] + Math.cos(time * 2) * 0.1;
    } else {
      ref.current.position.x += (position[0] - ref.current.position.x) * 0.1;
      ref.current.position.z += (position[2] - ref.current.position.z) * 0.1;
    }

    ref.current.position.y +=
      Math.sin(state.clock.elapsedTime + position[0]) * 0.001;
  });

  return (
    <Sphere
      ref={ref}
      position={position}
      args={[0.05, 16, 16]}
      onPointerOver={(e) => {
        e.stopPropagation();
        setHovered(true);
      }}
      onPointerOut={() => setHovered(false)}
    >
      <meshStandardMaterial color={color} transparent opacity={0.8} />
    </Sphere>
  );
};

export default Node;
