import { FC } from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";

const Privacy: FC = () => {
  return (
    <div className="min-h-screen bg-[#FAFAFA]">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6 py-24"
      >
        <article className="prose desktop:prose-lg laptop:prose-lg tablet:prose-base mobile:prose-base max-w-none">
          <h1 className="text-4xl font-bold text-gray-900 mb-12">
            Privacy Policy
          </h1>

          <p>
            This Privacy Policy describes the types of personal information that
            Solon Technologies Limited ("Quantly," "we," "our," and/or "us")
            collects, uses, and discloses from individuals who use our website,
            including https://www.quantly-ai.com/, and our services that link to
            this Privacy Policy (collectively, "Services"). By using our
            Services, you agree to the collection, use, disclosure, and
            procedures described in this Privacy Policy. Beyond the Privacy
            Policy, your use of our Services is also subject to our Terms of
            Use.
          </p>

          <section>
            <h2>PERSONAL INFORMATION WE COLLECT</h2>
            <p>
              We may collect a variety of personal information from or about you
              or your devices from various sources, as described below.
            </p>
          </section>

          <section>
            <h2>PERSONAL INFORMATION YOU PROVIDE TO US</h2>
            <p>
              Communications. If you contact us directly, we may receive
              personal information about you, such as your name, email address,
              phone number, city, the contents of a message or attachments that
              you may send to us, and other information you choose to provide.
              If you subscribe to our newsletter, then we will collect certain
              information from you, such as your email address. When we send you
              emails, we may track whether you open them to learn how to deliver
              a better customer experience and improve our Services.
            </p>
            <p>
              Prompts and Outputs. The Services allow you to submit text,
              documents, or other materials to the Services ("Prompts"), which
              generate responses based on your Prompts ("Outputs"). If you
              include personal information in your Prompts, we may collect and
              store that information in our database as part of the database of
              Prompts that we maintain.
            </p>
            <p>
              Payment Information. If you make a purchase through our Services,
              your payment-related information, such as credit card or other
              financial information, is collected by our third-party payment
              processor on our behalf.
            </p>
          </section>

          <section>
            <h2>PERSONAL INFORMATION WE COLLECT WHEN YOU USE OUR SERVICES</h2>
            <p>
              Location Information. When you use our Services, we may receive
              your location information for example, your IP address may
              indicate your general geographic region.
            </p>
            <p>
              Device Information. We receive information about the device and
              software you use to access our Services, including IP address, web
              browser type, operating system version, phone carrier and
              manufacturer, application installations, device identifiers,
              mobile advertising identifiers, and push notification tokens.
            </p>
            <p>
              Usage Information. To help us understand how you use our Services
              and to help us improve it, we automatically receive information
              about your interactions with our Services, such as the pages or
              other content you view and the dates and times of your visits.
            </p>
            <p>
              Information from Cookies and Similar Technologies. We and our
              third-party partners collect information using cookies, pixel
              tags, or similar technologies. Our third-party partners, such as
              analytics and advertising partners, may use these technologies to
              collect information about your online activities over time and
              across different services. Cookies are small text files containing
              a string of alphanumeric characters. We may use both session
              cookies and persistent cookies. A session cookie disappears after
              you close your browser. A persistent cookie remains after you
              close your browser and may be used by your browser on subsequent
              visits to our Services.
            </p>
            <p>
              Please review your web browser's "Help" file to learn how you may
              modify your cookie settings. Please note that if you delete or
              choose not to accept cookies from our Services, you may not be
              able to utilize the features of our Services to their fullest
              potential.
            </p>
          </section>

          <section>
            <h2>HOW WE USE THE PERSONAL INFORMATION WE COLLECT</h2>
            <p>We use the personal information we collect:</p>
            <ul>
              <li>To provide, maintain, improve, and enhance our Services;</li>
              <li>
                To understand and analyze how you use our Services and develop
                new products, services, features, and functionality;
              </li>
              <li>
                To communicate with you, provide you with updates and other
                information relating to our Services, provide information that
                you request, respond to comments and questions, and otherwise
                provide support;
              </li>
              <li>
                For marketing and advertising purposes, such as developing and
                providing promotional and advertising materials that may be
                relevant, valuable or otherwise of interest to you;
              </li>
              <li>
                To generate anonymized or aggregated data for any lawful
                purposes;
              </li>
              <li>
                To find and prevent fraud and abuse, and respond to trust and
                safety issues that may arise;
              </li>
              <li>
                For compliance purposes, including enforcing our Terms of
                Service or other legal rights, or as may be required by
                applicable laws and regulations or requested by any judicial
                process or governmental agency; and
              </li>
              <li>
                For other purposes for which we provide specific notice at the
                time the information is collected.
              </li>
            </ul>
          </section>

          <section>
            <h2>HOW WE DISCLOSE THE PERSONAL INFORMATION WE COLLECT</h2>
            <p>
              Partners and Affiliates. We may disclose any information we
              receive to our current or future affiliates for any of the
              purposes described in this Privacy Policy.
            </p>
            <p>
              Vendors and Service Providers. We may disclose any information we
              receive to vendors and service providers retained in connection
              with the operation of our Services.
            </p>
            <p>
              Analytics Partners. We use analytics services such as Google
              Analytics to collect and process certain analytics data. These
              services may also collect information about your use of other
              websites, apps, and online resources. You can learn more about
              Google's practices by visiting
              https://www.google.com/policies/privacy/partners/.
            </p>
            <p>
              As Required By Law and Similar Disclosures. We may access,
              preserve, and disclose your information if we believe doing so is
              required or appropriate to comply with law enforcement requests
              and legal process, such as a court order or subpoena, respond to
              your requests, or protect your, our, or others' rights, property,
              or safety.
            </p>
            <p>
              Merger, Sale, or Other Asset Transfers. We may transfer your
              information to service providers, advisors, potential
              transactional partners, or other third parties in connection with
              the consideration, negotiation, or completion of a corporate
              transaction in which we are acquired by or merged with another
              company or we sell, liquidate, or transfer all or a portion of our
              assets.
            </p>
            <p>
              Consent. We may also disclose your information with your
              permission.
            </p>
          </section>

          <section>
            <h2>YOUR CHOICES</h2>
            <p>
              Location Information. You can prevent your device from sharing
              precise location information at any time through your device's
              operating system settings.
            </p>
            <p>
              Marketing Communications. You can unsubscribe from our promotional
              emails via the link provided in the emails. Even if you opt out of
              receiving promotional messages from us, you will continue to
              receive administrative messages from us.
            </p>
            <p>
              Do Not Track. There is no accepted standard on how to respond to
              Do Not Track signals, and we do not respond to such signals.
            </p>
          </section>

          <section>
            <h2>THIRD PARTIES</h2>
            <p>
              Our Services may contain links to other websites, products, or
              services that we do not own or operate. We are not responsible for
              the privacy practices of these third parties. Please be aware that
              this Privacy Policy does not apply to your activities on these
              third-party services or any information you disclose to these
              third parties. We encourage you to read their privacy policies
              before providing any information to them.
            </p>
          </section>

          <section>
            <h2>SECURITY</h2>
            <p>
              We make reasonable efforts to protect your information by using
              security measures designed to safeguard the information we
              maintain. However, because no electronic transmission or storage
              of information can be entirely secure, we can make no guarantees
              as to the security or privacy of your information.
            </p>
          </section>

          <section>
            <h2>CHILDREN'S PRIVACY</h2>
            <p>
              We do not knowingly collect, maintain, or use personal information
              from children under 13 years of age, and no part of our Services
              is directed to children. If you learn that a child has provided us
              with personal information in violation of this Privacy Policy,
              then you may alert us at vlad@quantly-ai.com.
            </p>
          </section>

          <section>
            <h2>INTERNATIONAL VISITORS</h2>
            <p>
              Our Services is hosted in the United Kingdom ("UK") and intended
              for visitors located globally. If you choose to use our Services
              from other regions of the world with laws governing data
              collection and use that may differ from UK law, then please note
              that you are transferring your personal information outside of
              those regions to the UK for storage and processing. We may also
              transfer your data from the UK to other countries or regions in
              connection with storage and processing of data, fulfilling your
              requests, and operating our Services. By providing any
              information, including personal information, on or through our
              Services, you consent to such transfer, storage, and processing.
            </p>
          </section>

          <section>
            <h2>CHANGES TO THIS PRIVACY POLICY</h2>
            <p>
              We will post any updates to this Privacy Policy on this page, and
              the revised version will be effective when it is posted. If we
              make a material update, we may notify you of such update by
              through our Services (including our website), by email, or other
              means.
            </p>
          </section>

          <section>
            <h2>CONTACT INFORMATION</h2>
            <p>
              If you have any questions, comments, or concerns about our
              processing activities, please email us at vlad@quantly-ai.com or
              write to us at:
            </p>
            <p>
              Solon Technologies Limited
              <br />
              71-75 Shelton Street, London, England, WC2H 9JQ
            </p>
          </section>
        </article>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Privacy;
