import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiCloud, HiCog, HiSupport } from "react-icons/hi";

const features = [
  {
    icon: HiCloud,
    title: "Flexible Deployment",
    description:
      "Choose between cloud, on-premise, or hybrid deployment options",
  },
  {
    icon: HiCog,
    title: "Custom Integration",
    description: "Tailored implementation aligned with your existing systems",
  },
  {
    icon: HiSupport,
    title: "Dedicated Support",
    description: "24/7 expert assistance throughout the deployment process",
  },
];

const DeploymentSection: FC = () => {
  return (
    <div className="mb-32">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center"
      >
        <div>
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Custom End-to-End Deployment
          </h2>
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            className="mb-8"
          >
            {/* Server nodes */}
            <motion.rect
              x="20"
              y="20"
              width="20"
              height="30"
              rx="2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            />
            <motion.rect
              x="80"
              y="20"
              width="20"
              height="30"
              rx="2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            />
            <motion.rect
              x="50"
              y="70"
              width="20"
              height="30"
              rx="2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            />

            {/* Connection lines */}
            <motion.path
              d="M40 35H80"
              stroke="currentColor"
              strokeWidth="2"
              strokeDasharray="5,5"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 0.8 }}
            />
            <motion.path
              d="M30 50L60 70"
              stroke="currentColor"
              strokeWidth="2"
              strokeDasharray="5,5"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1 }}
            />
            <motion.path
              d="M90 50L60 70"
              stroke="currentColor"
              strokeWidth="2"
              strokeDasharray="5,5"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1.2 }}
            />

            {/* Data flow circles */}
            <motion.circle
              cx="60"
              cy="35"
              r="3"
              fill="currentColor"
              initial={{ scale: 0, x: -20 }}
              animate={{ scale: 1, x: 20 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
            <motion.circle
              cx="45"
              cy="60"
              r="3"
              fill="currentColor"
              initial={{ scale: 0, y: -10 }}
              animate={{ scale: 1, y: 10 }}
              transition={{
                duration: 1,
                delay: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
            <motion.circle
              cx="75"
              cy="60"
              r="3"
              fill="currentColor"
              initial={{ scale: 0, y: -10 }}
              animate={{ scale: 1, y: 10 }}
              transition={{
                duration: 1,
                delay: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.svg>
        </div>
        <div className="grid gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl hover:scale-105 hover:shadow-lg transition-all duration-300 transform will-change-transform"
            >
              <feature.icon
                className="w-6 h-6 text-black flex-shrink-0"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              />
              <div>
                <h3 className="text-gray-900 font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default DeploymentSection;
