import { FC } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Footer: FC = () => {
  return (
    <footer className="bg-black text-white py-16">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12"
        >
          <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-6">Company Information</h3>
            <p className="text-gray-400">Solon Technologies Limited</p>
            <p className="text-gray-400">71-75 Shelton Street</p>
            <p className="text-gray-400">London, England, WC2H 9JQ</p>
            <p className="text-gray-400">Company number: 14175327</p>
          </div>

          <div className="space-y-4 desktop:justify-self-end laptop:justify-self-end tablet:justify-self-start mobile:justify-self-start">
            <h3 className="text-lg font-semibold mb-6">Legal</h3>
            <div className="space-y-3">
              <div>
                <Link
                  to="/terms"
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                >
                  Terms of Service
                </Link>
              </div>
              <div>
                <Link
                  to="/privacy"
                  className="text-gray-400 hover:text-white transition-colors duration-300"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
