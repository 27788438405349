import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiOutlineClipboardList,
  HiOutlineServer,
  HiOutlineDatabase,
  HiOutlineChip,
  HiOutlineLightningBolt,
  HiOutlineChartSquareBar,
} from "react-icons/hi";

const milestones = [
  {
    icon: HiOutlineClipboardList,
    title: "Internal Data Discovery",
    points: [
      "Assessment of existing internal research repositories",
      "Mapping of data architecture",
    ],
  },
  {
    icon: HiOutlineServer,
    title: "Quantly Integration",
    points: [
      "Custom LLM engine deployment aligned with security protocols",
      "Connection of internal databases",
    ],
  },
  {
    icon: HiOutlineDatabase,
    title: "Data Harmonization",
    points: [
      "Integration with external data providers (Bloomberg, S&P)",
      "Unified data ecosystem creation",
    ],
  },
  {
    icon: HiOutlineChip,
    title: "Custom AI Enhancement",
    points: [
      "LLM configuration for team-specific requirements",
      "Information structuring and analysis setup",
    ],
  },
  {
    icon: HiOutlineLightningBolt,
    title: "Platform Activation",
    points: [
      "System launch and LLM research capabilities activation",
      "Initial insights generation",
    ],
  },
  {
    icon: HiOutlineChartSquareBar,
    title: "Evolution & Expansion",
    points: [
      "Use case exploration and capability building",
      "Continuous platform optimization",
    ],
  },
];

const ImplementationRoadmapSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Implementation Process
        </motion.h2>
        <div className="relative">
          {/* Icons above the line */}
          <div className="grid desktop:grid-cols-6 laptop:grid-cols-6 tablet:grid-cols-3 mobile:grid-cols-2 gap-6 mb-8">
            {milestones.map((milestone, index) => (
              <motion.div
                key={`icon-${index}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="flex flex-col items-center hover:scale-105 hover:shadow-xl transition-all duration-300 p-2 rounded-xl"
              >
                <div className="w-16 h-16 bg-black rounded-xl flex items-center justify-center">
                  <milestone.icon className="w-10 h-10 text-white" />
                </div>
              </motion.div>
            ))}
          </div>

          {/* Timeline line */}
          <motion.div
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="h-0.5 bg-gray-200 w-full my-8"
          />

          {/* Content below the line */}
          <div className="grid desktop:grid-cols-6 laptop:grid-cols-6 tablet:grid-cols-3 mobile:grid-cols-2 gap-6">
            {milestones.map((milestone, index) => (
              <motion.div
                key={`content-${index}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="text-center hover:scale-105 hover:shadow-xl transition-all duration-300 p-4 rounded-xl bg-white"
              >
                <h3 className="desktop:text-2xl laptop:text-2xl tablet:text-xl mobile:text-xl font-bold text-gray-900 mb-4">
                  {milestone.title}
                </h3>
                <ul className="space-y-3 text-left">
                  {milestone.points.map((point, pointIndex) => (
                    <li
                      key={pointIndex}
                      className="desktop:text-base laptop:text-base tablet:text-sm mobile:text-sm text-gray-600 leading-tight"
                    >
                      • {point}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImplementationRoadmapSlide;
