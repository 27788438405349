import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiShieldCheck,
  HiPuzzle,
  HiChip,
  HiAcademicCap,
  HiLightBulb,
  HiCheckCircle,
} from "react-icons/hi";

const challenges = [
  {
    icon: HiShieldCheck,
    title: "Data Security & Compliance",
    description:
      "Ensuring data is secure and does not leave the organisational infrastructure",
  },
  {
    icon: HiPuzzle,
    title: "Seamless Integration",
    description:
      "Connecting existing systems and proprietary data while maintaining full control",
  },
  {
    icon: HiChip,
    title: "AI Expertise",
    description:
      "Building and maintaining internally AI capabilities amid rapid technological change",
  },
  {
    icon: HiAcademicCap,
    title: "Analyst Training",
    description:
      "Training analysts to effectively leverage AI while preserving research excellence",
  },
  {
    icon: HiLightBulb,
    title: "Use Case Identification",
    description:
      "Determining highest-impact applications of GenAI across the research workflow",
  },
  {
    icon: HiCheckCircle,
    title: "Output Quality",
    description:
      "Delivering consistent, reliable, institutional-grade AI insights and analysis",
  },
];

const ChallengesSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          The Challenge To Effective Adoption of GenAI
        </motion.h2>
        <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-2 mobile:grid-cols-1 gap-6">
          {challenges.map((challenge, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-gradient-to-br from-gray-900 to-gray-800 p-8 rounded-2xl text-white hover:shadow-xl hover:scale-[1.02] transition-all duration-300"
            >
              <challenge.icon className="w-10 h-10 mb-4 text-white opacity-80" />
              <h3 className="text-xl font-semibold mb-3">{challenge.title}</h3>
              <p className="text-gray-300 font-light leading-relaxed">
                {challenge.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChallengesSlide;
