import { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

interface CircularProgressProps {
  percentage: number;
  color?: "red" | "green";
}

const CircularProgress: FC<CircularProgressProps> = ({
  percentage,
  color = "red",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const circumference = 2 * Math.PI * 120; // radius = 120
  const offset = circumference - (percentage / 100) * circumference;

  const colors = {
    red: {
      bg: "#FEE2E2",
      stroke: "#EF4444",
      text: "text-red-500",
    },
    green: {
      bg: "#DCFCE7",
      stroke: "#22C55E",
      text: "text-green-500",
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative w-80 h-80">
      <svg className="w-full h-full transform -rotate-90">
        {/* Background circle */}
        <circle
          cx="160"
          cy="160"
          r="120"
          stroke={colors[color].bg}
          strokeWidth="24"
          fill="none"
        />
        {/* Progress circle */}
        <motion.circle
          cx="160"
          cy="160"
          r="120"
          stroke={colors[color].stroke}
          strokeWidth="24"
          fill="none"
          strokeDasharray={circumference}
          initial={{ strokeDashoffset: circumference }}
          animate={{ strokeDashoffset: offset }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
        />
      </svg>
      {/* Percentage text */}
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-center"
        >
          <span className={`text-7xl font-bold ${colors[color].text}`}>
            {percentage}
          </span>
          <span className={`text-5xl font-bold ${colors[color].text}`}>%</span>
        </motion.div>
      </div>
    </div>
  );
};

export default CircularProgress;
