import { FC } from "react";
import TopBadge from "./content/TopBadge";
import MainContent from "./content/MainContent";
import StatsPanel from "./content/StatsPanel";

const ContentContainer: FC = () => {
  return (
    <div className="relative desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
      <div className="desktop:pt-48 laptop:pt-44 tablet:pt-36 mobile:pt-28">
        <TopBadge />
        <div className="grid desktop:grid-cols-12 laptop:grid-cols-12 tablet:grid-cols-1 mobile:grid-cols-1 gap-20">
          <MainContent />
          <StatsPanel />
        </div>
      </div>
    </div>
  );
};

export default ContentContainer;
