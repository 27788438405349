import { FC } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import IntroSlide from "../components/Slides/IntroSlide";
// import AgendaSlide from "../components/Slides/AgendaSlide";
import LandscapeSlide from "../components/Slides/LandscapeSlide";
import NewsArticlesSlide from "../components/Slides/NewsArticlesSlide";
import ImpactSlide from "../components/Slides/ImpactSlide";
import AnalystTodaySlide from "../components/Slides/AnalystTodaySlide";
import AIAugmentedAnalystSlide from "../components/Slides/AIAugmentedAnalystSlide";
import ChallengesSlide from "../components/Slides/ChallengesSlide";
import WhoWeAreSlide from "../components/Slides/WhoWeAreSlide";
import WhatWeDoAltSlide from "../components/Slides/WhatWeDoAltSlide";
import AccuracyBenchmarkSlide from "../components/Slides/AccuracyBenchmarkSlide";
import ImplementationRoadmapSlide from "../components/Slides/ImplementationRoadmapSlide";
import OutcomeSlide from "../components/Slides/OutcomeSlide";

const Slides: FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="h-screen w-screen bg-[#FAFAFA] overflow-hidden"
    >
      <Swiper
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        speed={1000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} w-2 h-2 bg-black opacity-50 hover:opacity-100 transition-opacity duration-300"></span>`;
          },
        }}
        navigation
        className="h-full w-full"
      >
        <SwiperSlide className="bg-[#FAFAFA]">
          <IntroSlide />
        </SwiperSlide>{" "}
        {/* <SwiperSlide className="bg-[#FAFAFA]">
          <AgendaSlide />
        </SwiperSlide> */}
        <SwiperSlide className="bg-[#FAFAFA]">
          <LandscapeSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <NewsArticlesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ImpactSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AnalystTodaySlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AIAugmentedAnalystSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <ChallengesSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <WhoWeAreSlide />
        </SwiperSlide>{" "}
        <SwiperSlide className="bg-[#FAFAFA]">
          {" "}
          <WhatWeDoAltSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <AccuracyBenchmarkSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          {" "}
          <ImplementationRoadmapSlide />
        </SwiperSlide>
        <SwiperSlide className="bg-[#FAFAFA]">
          <OutcomeSlide />
        </SwiperSlide>
      </Swiper>
    </motion.div>
  );
};

export default Slides;
