import { FC } from "react";
import { motion } from "framer-motion";
import { HiClock, HiDatabase, HiLightningBolt } from "react-icons/hi";

const points = [
  {
    icon: HiClock,
    title: "Task Automation",
    content:
      "GenAI automates simple, repetitive, time consuming work that takes up to 70% of analyst time",
  },
  {
    icon: HiDatabase,
    title: "Access to Deep Knowledge",
    content:
      "GenAI allows analysts to tap into vast datasets of historical unstructured information for insights",
  },
  {
    icon: HiLightningBolt,
    title: "Instant Insights & Analysis",
    content:
      "Analysts can get insights and run data analysis by asking questions in natural language",
  },
];

const ImpactSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Impact on Investment Research Practices
        </motion.h2>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          <div className="space-y-6">
            {points.map((point, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="flex items-start gap-6 bg-white p-8 rounded-2xl hover:shadow-lg transition-all duration-300 border border-gray-100"
              >
                <point.icon className="w-10 h-10 text-white flex-shrink-0 bg-black rounded-xl p-2" />
                <div>
                  <h3 className="text-2xl font-semibold text-gray-900 mb-3">
                    {point.title}
                  </h3>
                  <p className="text-gray-600 text-lg font-light leading-relaxed">
                    {point.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="desktop:block laptop:block tablet:hidden mobile:hidden"
          >
            <img
              src="https://createsiteai.b-cdn.net/c/_/6edf6027-8526-4709-b710-92e9bf252921"
              alt="Investment Research Impact"
              className="w-full h-auto rounded-2xl shadow-lg"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ImpactSlide;
