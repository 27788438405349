import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Product from "./pages/Product";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Slides from "./pages/Slides";
function App() {
  return (
    <Router>
      <Routes>
        {" "}
        <Route path="/" element={<Home />} />{" "}
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/slides" element={<Slides />} />
      </Routes>
    </Router>
  );
}

export default App;
