import { FC } from "react";

export const LayeredCircles: FC = () => (
  <svg
    className="absolute -right-40 -top-40"
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
  >
    <circle
      cx="500"
      cy="500"
      r="400"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
    />
    <circle
      cx="500"
      cy="500"
      r="300"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
    />
    <circle
      cx="500"
      cy="500"
      r="200"
      stroke="currentColor"
      strokeWidth="0.5"
      fill="none"
    />
  </svg>
);
