import { FC } from "react";
import { motion } from "framer-motion";
import { HiUserGroup, HiChip, HiLightningBolt } from "react-icons/hi";
import NetworkAnimation from "./components/NetworkAnimation";

const stats = [
  {
    icon: HiUserGroup,
    value: "20+",
    label: "Financial Institutions",
  },
  {
    icon: HiChip,
    value: "94%",
    label: "Most accurate financial LLM product on the market",
  },
  {
    icon: HiLightningBolt,
    value: "2022",
    label: "Founded",
  },
];

const WhoWeAreSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Who We Are
        </motion.h2>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          <div className="space-y-8 relative">
            <NetworkAnimation />
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <p className="text-2xl text-gray-800 leading-relaxed font-medium">
                Quantly is an AI lab focused on transforming investment
                research.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="pl-6 border-l-4 border-gray-200"
            >
              <p className="text-2xl text-gray-600 leading-relaxed font-light">
                We build enterprise-grade investment research infrastructure to
                allow financial institutions to leverage the power of LLMs in a
                custom and secure way.
              </p>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid desktop:grid-cols-1 laptop:grid-cols-1 tablet:grid-cols-2 mobile:grid-cols-1 gap-8"
          >
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 + index * 0.1 }}
                className="bg-black p-6 rounded-2xl text-white hover:scale-105 transition-all duration-300"
              >
                <stat.icon className="w-8 h-8 mb-3 text-white opacity-80" />
                <div className="text-3xl font-bold mb-2">{stat.value}</div>
                <div className="text-gray-300">{stat.label}</div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAreSlide;
