import { motion } from "framer-motion";
import { FC } from "react";
import { LayeredCircles } from "./geometric/LayeredCircles";
import { DotPattern } from "./geometric/DotPattern";
import { AbstractLines } from "./geometric/AbstractLines";

const BackgroundElements: FC = () => {
  return (
    <div className="absolute inset-0 overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.03 }}
        transition={{ duration: 1.5 }}
        className="absolute w-full h-full desktop:block laptop:block tablet:hidden mobile:hidden"
      >
        <LayeredCircles />
        <DotPattern />
        <AbstractLines />
      </motion.div>
    </div>
  );
};

export default BackgroundElements;
