import { FC } from "react";
import { motion } from "framer-motion";
import AccuracyScore from "./components/AccuracyScore";
import ContentSection from "./components/ContentSection";

const AIPerformance: FC = () => {
  return (
    <section className="bg-white py-24">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center">
          <AccuracyScore />
          <ContentSection />
        </div>
      </div>
    </section>
  );
};

export default AIPerformance;
