import { FC } from "react";

export const DotPattern: FC = () => (
  <svg
    className="absolute left-0 top-0 opacity-20"
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
  >
    <pattern id="dots" width="20" height="20" patternUnits="userSpaceOnUse">
      <circle cx="2" cy="2" r="1" fill="currentColor" />
    </pattern>
    <rect width="100%" height="100%" fill="url(#dots)" />
  </svg>
);
