import { motion } from "framer-motion";
import { FC } from "react";

const TopBadge: FC = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6 }}
    className="inline-flex items-center gap-2 bg-white border border-gray-100 rounded-full px-6 py-2.5 mb-12 shadow-sm hover:scale-105 hover:shadow-lg transition-all duration-300"
  >
    <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
    <span className="text-gray-800 desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs font-medium tracking-wide">
      AI-POWERED INVESTMENT RESEARCH
    </span>
  </motion.div>
);

export default TopBadge;
