import { FC } from "react";
import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";

const Terms: FC = () => {
  return (
    <div className="min-h-screen bg-[#FAFAFA]">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6 py-24"
      >
        <article className="prose desktop:prose-lg laptop:prose-lg tablet:prose-base mobile:prose-base max-w-none">
          <h1 className="text-4xl font-bold text-gray-900 mb-12">
            Terms of service
          </h1>

          <section>
            <h2>Important Terms</h2>
            <p>
              These evaluation terms of service are between Solon Technologies
              Limited ("Quantly") and You and govern Your use of the Quantly. If
              You are using Quantly on behalf of another entity (such as your
              employer), You must have the authority to accept these Terms on
              their behalf.
            </p>
            <p>
              Quantly may update the Terms by posting updated Terms on our
              website. All changes become effective when posted. Such changes
              are not retroactive, but Your continued use of the Quantly after
              any such changes means You agree to such changes. Notwithstanding
              the above, in no event may Quantly alter the Terms in a way that
              meaningfully detracts from its obligations with respect to
              Confidential Information, Customer Data, or Customer Content as
              agreed to in this Agreement without express written authorization
              from You.
            </p>
            <p>
              Quantly is an AI research and analytics software, and its Output
              is not financial advice. The Output of Quantly is AI-generated,
              and may contain errors and misstatements or may be incomplete.
            </p>
          </section>

          <section>
            <h2>Usage</h2>
            <p>
              You may access, and we grant You the non-exclusive right to use,
              the full Service of the Quantly. Access credentials are specific
              to the user to whom they are issued and may not be shared,
              including within the same organization.
            </p>
            <p>
              You will take reasonable steps to prevent unauthorized use of the
              Service and You will notify us promptly if You learn of any
              material unauthorized usage.
            </p>
            <p>
              Your usage of the Quantly is governed by the Terms. You will
              generally interact with the Quantly by providing Input to the
              Service and receiving Output from the Service.
            </p>
            <p>
              You may not (i) use Quantly in a way that infringes,
              misappropriates, or violates any person's rights; (ii) access or
              use Quantly from within any Embargoed Countries; (iii) attempt to
              reverse engineer or attempt to discover the source code or
              engineering of the underlying model and systems of Quantly or
              Quantly's subcontractors; (iv) attempt automated means to scrape
              content or Output from the Quantly.
            </p>
            <p>
              To the extent that You provide us with any Feedback, we may freely
              use and incorporate any Feedback into our products and services.
              Quantly may not utilize Feedback in a way that identifies, or
              could be used to identify, Customer, its users, Customer Data,
              Content, or Customer's Confidential Information.
            </p>
            <p>
              Any third party software, services, or other products You use in
              connection with the Quantly (for example, Your internet browser)
              are subject to their own terms, and we are not responsible for
              such third party products.
            </p>
          </section>

          <section>
            <h2>Content</h2>
            <p>
              You may provide Input to Quantly and receive Output from Quantly.
              As between the parties, You own Your Output subject to Your
              compliance with the Terms.
            </p>
            <p>
              You may provide Input that is similar or identical to third
              party's user's Input or may receive Output that is similar or
              identical to Output provided to other third party users. Queries
              that are requested by other third party users and responses
              provided to other third party users are not Your Content.
            </p>
            <p>
              Quantly may collect and use Usage Data to develop, improve,
              support, and operate its Services. Quantly may not share Usage
              Data that includes Your Confidential Information with a third
              party except (a) with our confidentiality sections or (b) to the
              extent the Usage Data is aggregated and anonymized such that You
              cannot reasonably be identified.
            </p>
          </section>

          <section>
            <h2>Customer Data</h2>
            <p>
              To utilize certain features, You may be required to upload
              documents ("Customer Data") into Quantly for the purpose of
              enabling certain features.
            </p>
            <p>
              As between the parties, You retain all right, title and interest
              (including any and all intellectual property rights) in and to the
              Customer Data. You grant to Quantly and its Affiliates a
              non-exclusive, worldwide, royalty-free right to process the
              Customer Data and Your Input to the extent necessary to provide
              the Service to You, to prevent or address service or technical
              problems with the Service, or as may be required by law.
            </p>
            <p>
              Your use of the Service and all Customer Data will comply with
              applicable laws, government regulations, and any other legal
              requirements, including but not limited to, any data localization
              or data sovereignty laws, regulations, and any other third-party
              legal requirements applicable to You. You are responsible for the
              accuracy, content and legality of all Customer Data. Your Customer
              Data will not be shared with any other user of the Service.
            </p>
          </section>

          <section>
            <h2>Payment and subscription</h2>
            <p>
              You are utilizing Quantly via an authorized free trial, You agree
              that you will do so in accordance with your instructions from
              Quantly. If we believe that you are not using the free trial in
              good faith, we may immediately terminate your access to the
              Service. We reserve the right to limit the resources and features
              available to free trial users.
            </p>
            <p>
              Subject to the terms and conditions of this Agreement, Quantly
              hereby grants to Customer a limited term, non-exclusive,
              non-transferable, non-sublicensable license for the Authorized
              Users to access via a web browser or mobile app the Services
              solely for internal business purposes or personal, non-commercial
              use (each such license for an Authorized User, a "Seat"). Sharing
              of Seats is not permitted. Usernames and passwords for each Seat
              distributed to Authorized Users may only be used by the Authorized
              User to whom the Seat is assigned. If anyone who is not an
              Authorized User accesses the Services using the username or
              password of an Authorized User, Customer shall purchase an
              additional Seat equivalent to the Seat of such Authorized User.
            </p>
            <p>
              The Agreement will take effect on the Effective Date and continue
              until the expiration or earlier termination of all Seats under the
              Agreement. Each Seat will activate and expire in accordance with
              the Subscription Period (the "Initial Term"). Upon expiration of
              the Initial Term, the Subscription Period for the Seats will
              automatically renew for additional terms of one (1) year (each an
              "Extended Term"); provided, however, that either party may choose
              not to renew a Seat at the end of either the Initial Term or any
              Extended Term upon written notice, for which email may suffice, to
              the other party delivered at least 60 days before the end of the
              Initial Term or the then-current Extended Term (whichever is
              applicable).
            </p>
          </section>

          <section>
            <h2>Term and termination</h2>
            <p>
              These Terms take effect when You first use Quantly and remain in
              effect until terminated. You may terminate these Terms at any time
              by discontinuing the use of the Service. We may similarly
              terminate upon notice at any time.
            </p>
            <p>
              Upon termination, You will stop using the Quantly, and You will
              promptly return, or if instructed by us, destroy any Confidential
              Information. The sections of these terms that customarily would
              survive such an agreement shall survive (for example, provisions
              around confidentiality, obligation to pay unpaid fees, etc.)
            </p>
          </section>

          <section>
            <h2>Warranty and disclaimer</h2>
            <p>
              You warrant that You have the necessary rights in Your Customer
              Data and Input to use it with Quantly and that Your use of the
              Quantly will comply with all applicable laws and regulations.
            </p>
            <p>
              Quantly is provided on an as-is and as-available basis. Quantly
              makes no representations or warranties of any kind, implied or
              expressed, with respect to the Quantly including warranties of
              merchantability, title, non-infringement, or fitness for a
              particular purpose, which are disclaimed. Quantly does not
              represent or warrant that the use of the Quantly will be
              uninterrupted or error-free.
            </p>
          </section>

          <section>
            <h2>Limitations of Liability</h2>
            <p>
              In no event will either party be liable to the other party or any
              third party for any indirect, incidental, special, exemplary,
              punitive, or consequential damages, including loss of income,
              data, profits, revenue, or business interruption, or the cost of
              substitute services or other economic loss, arising out of or in
              connection with these Terms, whether such liability arises from
              any claim based on contract, warranty, tort (including
              negligence), strict liability or otherwise, and whether or not
              such party has been advised of the possibility of such loss or
              damage
            </p>
            <p>
              Other than for claims based on liability which, by law, cannot be
              limited (for example, tort claims for gross negligence and
              intentional misconduct), in no event will either party's total
              liability to the other party or any third party for all claims in
              the aggregate (for damages or liability of any type) in connection
              with these Terms exceed $100.
            </p>
          </section>

          <section>
            <h2>General Terms</h2>
            <h3>Assignment</h3>
            <p>
              Neither party may assign these Terms without the advance written
              consent of the other party, except that Quantly may (i) assign
              these Terms in their entirety to any Affiliate and (ii) assign
              these Terms in connection with a consolidation, merger or sale of
              all or substantially all of our assets
            </p>

            <h3>Subcontracting</h3>
            <p>
              Quantly may use subcontractors and other third-party providers in
              connection with the performance of its activities under these
              Terms as it deems appropriate, provided that it remains
              responsible for the performance of any such subcontractors or
              third-party providers.
            </p>

            <h3>Severability and Interpretation</h3>
            <p>
              If a court of competent jurisdiction holds any provision of these
              Terms to be unenforceable or invalid, that provision will be
              limited to the minimum extent necessary so that these Terms will
              otherwise remain in effect. Section headings are inserted for
              convenience only and shall not affect the construction of these
              Terms.
            </p>

            <h3>Open Source Software</h3>
            <p>
              We warrant that we will not use any software in the Service that
              would cause Your software to become subject to an open source
              license that would require, as a condition of use, Your software
              to be disclosed or distributed in source code form or would give
              others the right to modify Your software
            </p>

            <h3>Confidentiality</h3>
            <p>
              Each party (as the "Receiving Party") will use the same degree of
              care that it uses to protect the confidentiality of its own
              confidential information of like kind (but not less than
              reasonable care) to: (i) not use any Confidential Information of
              the other party (the "Disclosing Party") for any purpose outside
              the scope of these Terms; and (ii) except as otherwise authorized
              by the Disclosing Party in writing, limit access to Confidential
              Information of the Disclosing Party to those of its and its
              Affiliates' employees and contractors who need that access for
              purposes consistent with these Terms and who are bound by
              confidentiality obligations to the Receiving Party containing
              protections not materially less protective this section. If the
              Receiving Party is required by law or court order to disclose
              Confidential Information, then the Receiving Party shall, to the
              extent legally permitted, provide the Disclosing Party with
              advance written notification and cooperate in any effort to obtain
              confidential treatment of the Confidential Information. The
              Receiving Party acknowledges that disclosure of Confidential
              Information would cause substantial harm for which damages alone
              would not be a sufficient remedy, and therefore that upon any such
              disclosure by the Receiving Party, the Disclosing Party will be
              entitled to seek appropriate equitable relief in addition to
              whatever other remedies it might have at law. Quantly reserves the
              right to use customers' logos for marketing communication
              purposes.
            </p>

            <h3>No Training by Subprocessors</h3>
            <p>
              Subprocessors will not train any AI models with Your Content or
              Customer Data. Subprocessors will not retain or log for human
              review Your Content or Customer Data.
            </p>

            <h3>Limitations on Training</h3>
            <p>
              Quantly will not train any AI models using Your Content or
              Customer Data.
            </p>

            <h3>Privacy Policy</h3>
            <p>
              Your users will be subject to our Privacy Policy to the extent not
              in conflict with the Terms in using the Quantly
            </p>

            <h3>Governing Law</h3>
            <p>
              These Terms will be governed by the laws of the United Kingdom
              without regard to conflicts of laws provisions thereof, and
              without regard to the United Nations Convention on the
              International Sale of Goods.
            </p>

            <h3>Notice</h3>
            <p>
              All notices must be in writing (in English) and addressed to the
              parties via email: (i) for Quantly, notice must be sent to
              vlad@quantly-ai.com; and (ii) for You, to the email address
              associated with Your user account. Notices will be deemed given
              upon receipt. Either Party may change its email address for
              notices under these Terms by providing the other Party written
              notice in accordance with this section
            </p>

            <h3>No Waiver</h3>
            <p>
              No waiver will be implied from conduct or failure to enforce or
              exercise rights under these Terms, nor will any waiver be
              effective unless in a writing signed by the waiving Party.
            </p>

            <h3>Entire Agreement</h3>
            <p>
              These Terms are the complete and exclusive statement of the mutual
              understanding of the parties in connection with Your use of
              Quantly and supersede and cancel all previous written and oral
              agreements, understandings, and communications relating to the
              subject matter in these Terms. Each party represents that, in
              connection with Quantly, it has not relied on any term or
              representation not contained in these Terms.
            </p>

            <h3>Force Majeure</h3>
            <p>
              Neither party will be liable to the other for any delay or failure
              to perform any obligation under these Terms if the delay or
              failure results from any cause beyond such party's reasonable
              control that could not have been prevented through the use of
              commercially reasonable safeguards, including acts of God, labor
              disputes, or other industrial disturbances, systemic electrical,
              telecommunications, or other utility failures, earthquake, storms
              or other elements of nature, blockages, embargoes, riots, public
              health emergencies (including pandemics and epidemics), acts or
              orders of government, acts of terrorism, or war
            </p>

            <h3>Entire Agreement</h3>
            <p>
              These Terms are the complete and exclusive statement of the mutual
              understanding of the parties in connection with Your use of
              Quantly and supersede and cancel all previous written and oral
              agreements, understandings, and communications relating to the
              subject matter in these Terms. Each party represents that, in
              connection with Quantly, it has not relied on any term or
              representation not contained in these Terms.
            </p>
          </section>
        </article>
      </motion.div>
      <Footer />
    </div>
  );
};

export default Terms;
