import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import Edge from "./Edge";
import Node from "./Node";

const Scene = () => {
  const [nodes, setNodes] = useState<[number, number, number][]>([]);
  const [edges, setEdges] = useState<{ start: number; end: number }[]>([]);

  useEffect(() => {
    const newNodes: [number, number, number][] = Array.from(
      { length: 20 },
      () => [
        (Math.random() - 0.5) * 8,
        (Math.random() - 0.5) * 8,
        (Math.random() - 0.5) * 8,
      ],
    );

    const newEdges = Array.from({ length: 30 }, () => ({
      start: Math.floor(Math.random() * newNodes.length),
      end: Math.floor(Math.random() * newNodes.length),
    }));

    setNodes(newNodes);
    setEdges(newEdges);
  }, []);

  return (
    <Canvas camera={{ position: [0, 0, 8] }}>
      <ambientLight intensity={0.7} />
      <pointLight position={[10, 10, 10]} />
      {nodes.map((position, i) => (
        <Node key={i} position={position} />
      ))}
      {edges.map((edge, i) => (
        <Edge key={i} start={nodes[edge.start]} end={nodes[edge.end]} />
      ))}
    </Canvas>
  );
};

export default Scene;
