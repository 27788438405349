import { FC } from "react";
import { motion } from "framer-motion";
import TimelineStep from "./TimelineStep";
import GridBackground from "./components/GridBackground";
import {
  HiDatabase,
  HiRefresh,
  HiLightningBolt,
  HiChartBar,
} from "react-icons/hi";

const steps = [
  {
    icon: HiDatabase,
    title: "Connect Data",
    description:
      "Seamlessly integrate with your internal research library and data",
  },
  {
    icon: HiRefresh,
    title: "Sync Sources",
    description: "Combine your internal data with external market sources",
  },
  {
    icon: HiLightningBolt,
    title: "Process",
    description:
      "Apply Quantly's LLM engine to analyze and structure information, customized to your team",
  },
  {
    icon: HiChartBar,
    title: "Insights",
    description:
      "Generate actionable insights and ideas through your in-house LLM research platform",
  },
];

const IntegrationProcess: FC = () => {
  return (
    <section className="bg-white py-24 relative overflow-hidden">
      <GridBackground />
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6 relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 text-center mb-16"
        >
          Integration Process
        </motion.h2>
        <div className="relative">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-100 -translate-y-1/2 desktop:block laptop:block tablet:hidden mobile:hidden"
          />
          <div className="grid desktop:grid-cols-4 laptop:grid-cols-4 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
            {steps.map((step, index) => (
              <TimelineStep
                key={index}
                icon={step.icon}
                title={step.title}
                description={step.description}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationProcess;
