import { Line } from "@react-three/drei";

interface EdgeProps {
  start: [number, number, number];
  end: [number, number, number];
}

const Edge = ({ start, end }: EdgeProps) => {
  return (
    <Line
      points={[start, end]}
      color="rgba(255, 255, 255, 0.8)"
      lineWidth={1}
      transparent
      opacity={0.4}
    />
  );
};

export default Edge;
