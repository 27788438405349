import { FC } from "react";
import { motion } from "framer-motion";

const ContentSection: FC = () => {
  return (
    <div className="space-y-8">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="text-4xl font-bold text-gray-900"
      >
        Best-in-Class Proprietary AI Technology
      </motion.h2>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-gray-600 text-lg font-light leading-relaxed"
      >
        {" "}
        Quantly's AI investment research technology achieved an industry-leading
        94% accuracy score on FinanceBench, the standard benchmark for financial
        Q&A with LLMs. This exceptional performance demonstrates Quantly's
        commitment to delivering accurate, reliable investment insights through
        our cutting-edge AI technology. We don't just claim it. We show that we
        are better than anyone else.
      </motion.p>
    </div>
  );
};

export default ContentSection;
