import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiChip, HiLightningBolt, HiCog } from "react-icons/hi";

const features = [
  {
    icon: HiChip,
    title: "Most Advanced Proprietary LLM Technology",
    description:
      "State-of-the-art proprietary language model designed specifically for financial analysis",
  },
  {
    icon: HiLightningBolt,
    title: "Regular System Upgrades to Latest AI Advances",
    description:
      "Continuous integration of cutting-edge AI innovations and improvements",
  },
  {
    icon: HiCog,
    title: "Your Personal AI Expert Team",
    description:
      "Direct access to specialized AI experts for customized support and optimization",
  },
];

const AISection: FC = () => {
  return (
    <div className="mb-32">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center"
      >
        <div className="desktop:order-2 laptop:order-2 tablet:order-1 mobile:order-1">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Cutting-edge AI
          </h2>
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            className="mb-8"
          >
            {/* Brain outline */}
            <motion.path
              d="M60 20C40 20 25 35 25 55C25 75 40 90 60 90C80 90 95 75 95 55C95 35 80 20 60 20Z"
              stroke="currentColor"
              strokeWidth="4"
              fill="none"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
            />
            {/* Circuit paths */}
            <motion.path
              d="M40 55H80M60 35V75M45 40L75 70M75 40L45 70"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              fill="none"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1.2, delay: 1 }}
            />
            {/* Pulse circle */}
            <motion.circle
              cx="60"
              cy="55"
              r="5"
              fill="currentColor"
              initial={{ scale: 0.8, opacity: 0.5 }}
              animate={{ scale: 1.2, opacity: 1 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.svg>
        </div>
        <div className="grid gap-6 desktop:order-1 laptop:order-1 tablet:order-2 mobile:order-2">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl hover:scale-105 hover:shadow-lg transition-all duration-300 transform will-change-transform"
            >
              <feature.icon
                className="w-6 h-6 text-black flex-shrink-0"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              />
              <div>
                <h3 className="text-gray-900 font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default AISection;
