import { FC, useState } from "react";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import { useEffect } from "react";

const AccuracyScore: FC = () => {
  return (
    <a
      href="https://quantly.substack.com/p/evaluation-of-quantly-on-financebench"
      target="_blank"
      rel="noopener noreferrer"
      className="block"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        style={{
          background: "linear-gradient(45deg, #000 0%, #000 100%)",
          position: "relative",
          overflow: "hidden",
        }}
        className="rounded-3xl p-12 text-center relative group"
      >
        <div className="text-white mb-6">
          <span className="text-8xl font-bold">94</span>
          <span className="text-6xl font-bold">%</span>
        </div>
        <p className="text-gray-400 text-xl">Score on FinanceBench</p>
        <div className="mt-6 text-sm text-gray-500">
          Highest in the Industry
        </div>
        <motion.div
          className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity duration-300"
          style={{
            background:
              "linear-gradient(45deg, transparent 0%, white 50%, transparent 100%)",
            backgroundSize: "200% 100%",
          }}
          animate={{
            backgroundPosition: ["100% 0%", "-33% 100%"],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear",
          }}
        />{" "}
      </motion.div>
    </a>
  );
};

export default AccuracyScore;
