import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiDatabase, HiRefresh, HiTemplate } from "react-icons/hi";

const features = [
  {
    icon: HiDatabase,
    title: "Data Integrations",
    description: "Seamlessly combine internal and external data sources",
  },
  {
    icon: HiRefresh,
    title: "Real-time Sync",
    description:
      "Automatic updates and synchronization across all data sources",
  },
  {
    icon: HiTemplate,
    title: "Trustworthy Data Partners",
    description: "External data partners include S&P Capital IQ and Bloomberg",
  },
];

const DataSection: FC = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-16 items-center"
      >
        <div className="desktop:order-2 laptop:order-2 tablet:order-1 mobile:order-1">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Single Source of Truth
          </h2>{" "}
          <motion.svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
            className="mb-8"
          >
            {/* Central hub */}
            <motion.circle
              cx="60"
              cy="60"
              r="15"
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.8 }}
            />

            {/* Radiating connection points */}
            {[0, 60, 120, 180, 240, 300].map((angle, index) => (
              <g key={angle}>
                <motion.circle
                  cx={60 + Math.cos((angle * Math.PI) / 180) * 40}
                  cy={60 + Math.sin((angle * Math.PI) / 180) * 40}
                  r="6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4, delay: index * 0.1 }}
                />
                <motion.line
                  x1="60"
                  y1="60"
                  x2={60 + Math.cos((angle * Math.PI) / 180) * 40}
                  y2={60 + Math.sin((angle * Math.PI) / 180) * 40}
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeDasharray="5,5"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 1, delay: 0.5 + index * 0.1 }}
                />
                <motion.circle
                  cx={60 + Math.cos((angle * Math.PI) / 180) * 40}
                  cy={60 + Math.sin((angle * Math.PI) / 180) * 40}
                  r="2"
                  fill="currentColor"
                  initial={{ scale: 0 }}
                  animate={{ scale: [0, 1, 0] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    delay: index * 0.2,
                  }}
                />
              </g>
            ))}

            {/* Pulse animation for central hub */}
            <motion.circle
              cx="60"
              cy="60"
              r="8"
              fill="currentColor"
              initial={{ scale: 0.8, opacity: 0.5 }}
              animate={{ scale: 1.2, opacity: 0 }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
            />
          </motion.svg>
        </div>
        <div className="grid gap-6 desktop:order-1 laptop:order-1 tablet:order-2 mobile:order-2">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl hover:scale-105 hover:shadow-lg transition-all duration-300 transform will-change-transform"
            >
              <feature.icon
                className="w-6 h-6 text-black flex-shrink-0"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              />
              <div>
                <h3 className="text-gray-900 font-semibold mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default DataSection;
