import { FC } from "react";
import { motion } from "framer-motion";

const Logo: FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="fixed desktop:top-8 laptop:top-8 tablet:top-6 mobile:top-6 desktop:left-8 laptop:left-8 tablet:left-6 mobile:left-6 z-50"
    >
      <motion.img
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.2 }}
        src="https://createsiteai.b-cdn.net/c/_/4cc4f85d-86a6-46ed-b84c-c3111e974f89"
        alt="Quantly Logo"
        className="desktop:w-12 laptop:w-12 tablet:w-10 mobile:w-8 h-auto"
      />
    </motion.div>
  );
};

export default Logo;
