import { FC } from "react";
import { motion } from "framer-motion";
import { IconType } from "react-icons";

interface TimelineStepProps {
  icon: IconType;
  title: string;
  description: string;
  index: number;
}

const TimelineStep: FC<TimelineStepProps> = ({
  icon: Icon,
  title,
  description,
  index,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="relative"
    >
      <div className="flex flex-col items-center text-center group">
        <motion.div
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.2 }}
          className="w-16 h-16 bg-black rounded-full flex items-center justify-center mb-6 relative z-10 group-hover:shadow-lg"
        >
          <Icon className="w-8 h-8 text-white" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900 mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
          {title}
        </h3>
        <p className="text-gray-600 font-light group-hover:translate-y-[-2px] transition-transform duration-300">
          {description}
        </p>
      </div>
    </motion.div>
  );
};

export default TimelineStep;
