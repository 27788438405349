import { FC } from "react";
import { motion } from "framer-motion";
import {
  HiLightningBolt,
  HiUserGroup,
  HiLightBulb,
  HiChat,
} from "react-icons/hi";

const outcomes = [
  {
    icon: HiLightningBolt,
    title: "Future-ready investment research infrastructure",
    description:
      "Strong foundations to continue building for use cases and leverage new capabilities",
    iconGradient: "from-blue-400 to-blue-600",
  },
  {
    icon: HiUserGroup,
    title: "Personal AI analyst for every analyst",
    description:
      "Every analyst will have a powerful helping hand, an extension of them",
    iconGradient: "from-purple-400 to-purple-600",
  },
  {
    icon: HiLightBulb,
    title: "Analyst Value Creation",
    description:
      "Shift from routine tasks to generating unique insights and deeper analysis",
    iconGradient: "from-green-400 to-green-600",
  },
  {
    icon: HiChat,
    title: "Client Engagement",
    description:
      "More time for high-value client interactions and customized research delivery",
    iconGradient: "from-orange-400 to-orange-600",
  },
];

const OutcomeSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12 relative overflow-hidden">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Outcome
        </motion.h2>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {outcomes.map((outcome, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 40, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: index * 0.2,
                type: "spring",
                stiffness: 100,
              }}
              className="bg-gradient-to-br from-black to-gray-900 p-8 rounded-2xl hover:shadow-xl transition-all duration-500 group transform hover:scale-105 hover:rotate-1 relative overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-black/0 via-white/5 to-black/0 translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-1000" />
              <div
                className={`w-14 h-14 bg-gradient-to-br ${outcome.iconGradient} rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300 shadow-lg`}
              >
                <outcome.icon className="w-7 h-7 text-white" />
              </div>
              <h3 className="text-xl font-bold text-white mb-3 group-hover:translate-y-[-2px] transition-transform duration-300">
                {outcome.title}
              </h3>
              <p className="text-gray-300 leading-relaxed text-base group-hover:translate-y-[-2px] transition-transform duration-300">
                {outcome.description}
              </p>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="absolute inset-0 -z-10 opacity-5"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.05 }}
          transition={{ duration: 1 }}
        >
          <div className="absolute inset-0 grid grid-cols-12 gap-2">
            {Array.from({ length: 12 }).map((_, i) => (
              <div key={i} className="h-full border-r border-black" />
            ))}
          </div>
          <div className="absolute inset-0 grid grid-rows-12 gap-2">
            {Array.from({ length: 12 }).map((_, i) => (
              <div key={i} className="w-full border-b border-black" />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default OutcomeSlide;
