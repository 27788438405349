import { FC } from "react";

export const AbstractLines: FC = () => (
  <svg className="absolute left-1/3 top-1/3" width="600" height="600">
    <line
      x1="0"
      y1="0"
      x2="600"
      y2="600"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <line
      x1="600"
      y1="0"
      x2="0"
      y2="600"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <line
      x1="300"
      y1="0"
      x2="300"
      y2="600"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
);
