import { FC } from "react";
import { motion } from "framer-motion";

const GridBackground: FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.05 }}
      transition={{ duration: 1 }}
      className="absolute inset-0 z-0"
    >
      <div className="relative w-full h-full">
        {/* Horizontal lines */}
        <div className="absolute inset-0 grid grid-rows-12">
          {Array.from({ length: 12 }).map((_, index) => (
            <div key={`h-${index}`} className="border-t border-black" />
          ))}
        </div>

        {/* Vertical lines */}
        <div className="absolute inset-0 grid grid-cols-12">
          {Array.from({ length: 12 }).map((_, index) => (
            <div key={`v-${index}`} className="border-l border-black" />
          ))}
        </div>

        {/* Diagonal lines */}
        <svg
          className="absolute inset-0 w-full h-full"
          preserveAspectRatio="none"
        >
          <pattern
            id="diagonal-pattern"
            width="100"
            height="100"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M0 0L100 100M-50 50L50 150M50 -50L150 50"
              stroke="currentColor"
              strokeWidth="0.5"
              fill="none"
            />
          </pattern>
          <rect width="100%" height="100%" fill="url(#diagonal-pattern)" />
        </svg>
      </div>
    </motion.div>
  );
};

export default GridBackground;
