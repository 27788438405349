import { FC } from "react";
import SecuritySection from "./components/SecuritySection";
import AISection from "./components/AISection";
import DeploymentSection from "./components/DeploymentSection";
import DataSection from "./components/DataSection";

const TechnologyPillars: FC = () => {
  return (
    <section className="bg-white py-24">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto px-6">
        <SecuritySection />
        <AISection />
        <DeploymentSection />
        <DataSection />
      </div>
    </section>
  );
};

export default TechnologyPillars;
