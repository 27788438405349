import { FC } from "react";
import { motion } from "framer-motion";
import { HiShieldCheck, HiChip, HiCloud, HiDatabase } from "react-icons/hi";

const features = [
  {
    icon: HiShieldCheck,
    title: "Enterprise-Grade Security",
    description:
      "The highest standards for data privacy and security throughout the whole process",
    iconBg: "bg-blue-500",
  },
  {
    icon: HiChip,
    title: "Best-in-Class AI Technology",
    description: "Best-in-class proprietary AI tech applied to research",
    iconBg: "bg-purple-500",
  },
  {
    icon: HiCloud,
    title: "Custom End-to-End Deployment",
    description: "Flexible cloud, on-premise, or hybrid deployment options",
    iconBg: "bg-green-500",
  },
  {
    icon: HiDatabase,
    title: "Single Source of Truth",
    description:
      "Unified platform combining internal and external data sources for research",
    iconBg: "bg-orange-500",
  },
];

const WhatWeDoAltSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          We Build Enterprise-Grade Investment Infrastructure
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="inline-flex items-center gap-2 bg-white border border-gray-100 rounded-full px-6 py-2.5 mb-12 shadow-sm"
        >
          <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
          <span className="text-gray-800 desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs font-medium tracking-wide">
            AI-POWERED INVESTMENT RESEARCH
          </span>
        </motion.div>

        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white p-8 rounded-2xl border border-gray-100 hover:shadow-xl transition-all duration-300 group"
            >
              <div
                className={`w-16 h-16 ${feature.iconBg} rounded-2xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300`}
              >
                <feature.icon className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4 group-hover:translate-y-[-2px] transition-transform duration-300">
                {feature.title}
              </h3>
              <p className="text-gray-600 leading-relaxed group-hover:translate-y-[-2px] transition-transform duration-300">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoAltSlide;
