import { FC } from "react";
import { motion } from "framer-motion";
import { HiChartBar, HiCheckCircle, HiTrendingUp } from "react-icons/hi";

const features = [
  {
    icon: HiChartBar,
    title: "Industry-Leading Performance",
    description:
      "Highest accuracy score achieved on FinanceBench, the standard benchmark for financial Q&A",
  },
  {
    icon: HiCheckCircle,
    title: "Validated Results",
    description:
      "Independently verified performance through rigorous testing and evaluation",
  },
  {
    icon: HiTrendingUp,
    title: "Consistent Excellence",
    description:
      "Maintained superior accuracy across diverse financial analysis tasks",
  },
];

const AccuracyBenchmarkSlide: FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-center p-12">
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-2xl mobile:max-w-sm mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-4xl font-bold text-gray-900 mb-12"
        >
          Best in Class: Most Accurate Finance LLM Product on the Market
        </motion.h2>
        <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="flex flex-col items-center justify-center bg-black p-12 rounded-2xl"
          >
            <div className="text-white mb-6">
              <span className="text-8xl font-bold">94</span>
              <span className="text-6xl font-bold">%</span>
            </div>
            <p className="text-gray-400 text-xl text-center">
              Score on FinanceBench
            </p>
            <div className="mt-6 text-sm text-gray-500 text-center">
              Highest in the Industry
            </div>
          </motion.div>
          <div className="space-y-6">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="flex items-start gap-4 bg-[#FAFAFA] p-6 rounded-2xl hover:scale-105 hover:shadow-lg transition-all duration-300"
              >
                <feature.icon className="w-8 h-8 text-black flex-shrink-0" />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccuracyBenchmarkSlide;
